import React, { ReactNode } from "react"

import { MultilineText } from "../../multiline-text"
import s from "./sap-module.module.scss"

export interface SapModuleProps {
  title: string
  subtitle: string[]
  leftColumn: ReactNode
  rightColumn?: ReactNode
  last?: boolean
}

export const SapModule = ({
  title,
  subtitle,
  leftColumn,
  rightColumn,
  last,
}: SapModuleProps) => (
  <div className={`${s.sapModule} ${last ? "" : s.notLast}`}>
    <div className={s.anchor} id={title.replace(/ /g, "")}></div>
    <div className={`${s.header} page-color`}>
      <div
        className={s.title}
        dangerouslySetInnerHTML={{ __html: title.replace(" ", "&nbsp;") }}
      ></div>
      <div className={s.subtitle}>
        <MultilineText text={subtitle} />
      </div>
    </div>
    <div className={s.body}>
      <div>{leftColumn}</div>
      {rightColumn && <div className={s.rightColumn}>{rightColumn}</div>}
    </div>
  </div>
)
