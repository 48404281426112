import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { ArrowDown } from "../arrow-down"
import { GetInTouch } from "../get-in-touch"
import { ImageBackground } from "../image-background"
import { Link } from "../link"
import { MultilineText } from "../multiline-text"
import { TwoColumns } from "../two-columns.tsx"
import { SapModule } from "./components/sap-module"
import { SapDataProps } from "./dataProps"
import s from "./sap.module.scss"

export interface SapProps extends SapDataProps {}

export const Sap = ({
  implementationText,
  support: {
    subtitle: supportSubtitle,
    leftColumn: supportLeftColumn,
    rightColumn: supportRightColumn,
  },
  sections,
  getInTouchSlogan,
}: SapProps) => {
  const sectionsCoeff = Math.max(2.5, sections.length)
  return (
    <>
      <ImageBackground outerClassName={s.mainImage}>
        <StaticImage
          placeholder="none"
          layout="fluid"
          maxWidth={5120}
          src="img/sap-main.png"
          alt="Strategy to Grow"
          transformOptions={{}}
          blurredOptions={{}}
        />
        <div id={s.implementation}>
          <h1>
            <span>Implementation</span>
          </h1>
          <p>{implementationText}</p>
        </div>
        <div id={s.support}>
          <h1>
            <span>Support</span>
          </h1>
          <p className="page-color">
            <MultilineText text={supportSubtitle} />
          </p>
        </div>
      </ImageBackground>
      <TwoColumns
        id={s.supportContent}
        leftColumn={<div id={s.supportLeftColumn}>{supportLeftColumn}</div>}
        rightColumn={<div id={s.supportRightColumn}>{supportRightColumn}</div>}
      />
      <div id={s.mainMenu}>
        <svg version="1.1" viewBox="0 0 134 110" className={s.background}>
          <filter id="shadow-0.5">
            <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          </filter>
          <polygon
            className="shadow"
            points="134,1 0,30 134,101"
            filter="url(#shadow-0.5)"
          />
          <polygon className="gray" points="134,1 0,30 134,101" />
          <polygon
            className="shadow"
            points="0,3 134,101 0,101"
            filter="url(#shadow-0.5)"
          />
          <polygon className="page-color" points="0,3 134,101 134,110 0,110" />
        </svg>
        <div>
          <div>
            <ul className="page-color">
              {sections.map(section => (
                <li
                  key={section.title}
                  style={{
                    borderBottomWidth: `${0.9 / sectionsCoeff}rem`,
                    marginBottom: `${4.5 / sectionsCoeff}rem`,
                  }}
                >
                  <Link
                    refId={section.title.replace(/ /g, "")}
                    style={{
                      fontSize: `${12 / sectionsCoeff}rem`,
                      marginRight: `${16.5 / sectionsCoeff}rem`,
                    }}
                  >
                    {section.title}
                    <ArrowDown
                      className="page-color"
                      style={{
                        marginTop: `-${1.8 / sectionsCoeff}rem`,
                        height: `${5.4 / sectionsCoeff}rem`,
                        width: `${10.8 / sectionsCoeff}rem`,
                      }}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {sections.map((section, i) => (
        <div
          key={section.title}
          className={`${s.section} ${
            i === 0 ? s.first : ""
          } page-background-color`}
        >
          {i === 0 && <div className={s.first}></div>}
          <SapModule {...section} last={i === sections.length - 1} />
        </div>
      ))}
      <GetInTouch slogan={getInTouchSlogan} />
    </>
  )
}
