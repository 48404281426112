import React from "react"

import { Layout } from "../components/layout"
import { Sap } from "../components/sap"
import { sapContent } from "../content/sap"

const { pageTitle, ...sapProps } = sapContent

export default function SapPage() {
  return (
    <Layout pageTitle={pageTitle} color="yellow">
      <Sap {...sapProps} />
    </Layout>
  )
}
