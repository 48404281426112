// Do not edit this file! It is generated by prebuild script

import React from "react"

import { SapContent } from "../content-types"

export const sapContent: SapContent = {
  support: {
    subtitle: [
      "We offer post go live maintenance",
      "support with various and flexible formulas:",
    ],
    leftColumn: (
      <>
        <p>
          <strong>Onsite support</strong>
        </p>
        <p>
          We can support your business onsite with the whole range of logistics
          modules and processes: procurement, sales and distribution, logistics
          execution, warehouse operations, transportation and global trade. Our
          consultants located in Brussels can easily reach to your premises and
          ensure business continuity.
        </p>
        <p>
          <strong>Remote support</strong>
        </p>
        <p>
          Depending on the context and business case, we can support your
          business remotely and provide flexible and reliable support on
          demand/call.
        </p>
      </>
    ),
    rightColumn: (
      <>
        <p>
          The above two approaches can be combined in order to meet your
          business requirements. Our company is currently supporting various
          businesses in the above described support formulas.
        </p>
        <p>
          We offer a combination of high level of expertise, flexibility
          (onsite/remote) on reasonable cost to ensure the successful support of
          your business, because based on real needs and demands.
        </p>
        <p>
          We are also professional trainers and can help your business team to
          upgrade regularly their knowledge and expertise, both system and
          business wise.
        </p>
      </>
    ),
  },
  pageTitle: "SAP",
  implementationText:
    "Our company has participated and led numerous ERP implementations in most of the leading European countries. We are experts in all the phases of the implementation starting with the requirement gathering till the successful delivery of the implemented system. We can manage not only the system related aspects, but also the project management and methodology including planning, budget and sourcing. Based on the two-decade experience of our team, we can guide you through the challenges of the ERP transformation, not only on system level, but also on process and organisation levels.",
  sections: [
    {
      title: "SAP GTS",
      subtitle: [
        "We provide expertise and implementation",
        "support of the main GTS modules",
      ],
      leftColumn: (
        <>
          <p>
            <strong>CUSTOMS MANAGMENT</strong>
          </p>
          <ul>
            <li>Import</li>
            <li>Export</li>
            <li>Transit</li>
            <li>
              Special customs regimes (Inward processing, Outward processing,
              Bonded warehouse)
            </li>
            <li>Classification</li>
            <li>Customs Duty Calculation</li>
            <li>
              EDI (France (Delta), Germany (ATLAS), The Netherlands (AGS/DMS),
              Belgium (PLDA)…)
            </li>
          </ul>
        </>
      ),
      rightColumn: (
        <>
          <p>
            <strong>COMPLIANCE MANAGMENT</strong>
          </p>
          <ul>
            <li>Legal Control</li>
            <li>Sanctioned Party Screening (SPL) and Embargo</li>
          </ul>
          <p>
            <strong>PREFERENCE CALCULATION AND DETERMINATION</strong>
          </p>
          <ul>
            <li>Request vendor LTVD (Long term vendor declaration)</li>
            <li>Issue/revoke customer LTVD</li>
            <li>Preference statement on the invoice EUR1/REX/ATR</li>
          </ul>
        </>
      ),
    },
    {
      title: "SAP ERP",
      subtitle: [
        "We support you with the implementation of",
        "the main logistics modules",
      ],
      leftColumn: (
        <>
          <p>
            <strong>MM MATERIALS MANAGEMENT</strong>
          </p>
          <ul>
            <li>Sourcing</li>
            <li>Purchasing</li>
            <li>Inventory management</li>
            <li>Material Valuation</li>
            <li>Material master data</li>
            <li>Vendor master data</li>
          </ul>
        </>
      ),
      rightColumn: (
        <>
          <p>
            <strong>SD SALES AND DISTRIBUTION</strong>
          </p>
          <ul>
            <li>Sales processing</li>
            <li>Pricing</li>
            <li>Billing</li>
            <li>Customer master data</li>
          </ul>
          <p>
            <strong>WM WAREHOUSE MANAGEMENT</strong>
          </p>
          <ul>
            <li>Delivery processing</li>
            <li>Integration with 3PL</li>
            <li>Picking/Packing – Warehouse movements</li>
            <li>Shipping</li>
          </ul>
        </>
      ),
    },
    {
      title: "SAP TM",
      subtitle: ["We have experience in implementing SAP TM"],
      leftColumn: (
        <>
          <ul>
            <li>
              <strong>Freight order and Freight booking management</strong>
            </li>
            <li>
              <strong>Integration with ECC/S/4 – OTR/DTR</strong>
            </li>
            <li>
              <strong>Export declaration integration with SAP GTS</strong>
            </li>
            <li>
              <strong>Compliance Integration with SAP GTS</strong>
            </li>
            <li>
              <strong>Master data</strong>
            </li>
            <li>
              <strong>Forwarding order management</strong>
            </li>
          </ul>
        </>
      ),
      rightColumn: null,
    },
  ],
  getInTouchSlogan: ["Start a new project"],
}
